// Lightmode colors
$primary: #EC255A;
$primary-2: #eed2d9;
$primary-text: #f9fafb;
$base-bg: #ffffff;
$base-bg-2: #f6f6f6;
$base-bg-3: #f1f3f4;
$base-text: #191a1a;
$base-text-2: #555555;
$logo-text:  #191a1a;

// Darkmode colors
$primary-dark: #EC255A;
$primary-2-dark: #eed2d9;
$primary-text-dark: #f9fafb;
$base-bg-dark: #121418;
$base-bg-2-dark: #1d2026;
$base-bg-3-dark: #24272d;
$base-text-dark: #F4F4F5;
$base-text-2-dark: #D1D5DB;
$logo-text-dark: #F4F4F5;

$zs-colors-light: (
    "primary": $primary,
    "primary-2": $primary-2,
    "primary-text": $primary-text,
    "base-bg": $base-bg,
    "base-bg-2": $base-bg-2,
    "base-bg-3": $base-bg-3,
    "base-text": $base-text,
    "base-text-2": $base-text-2,
    "logo-text": $logo-text
);

$zs-colors-dark: (
    "primary": $primary-dark,
    "primary-2": $primary-2-dark,
    "primary-text": $primary-text-dark,
    "base-bg": $base-bg-dark,
    "base-bg-2": $base-bg-2-dark,
    "base-bg-3": $base-bg-3-dark,
    "base-text": $base-text-dark,
    "base-text-2": $base-text-2-dark,
    "logo-text": $logo-text-dark
);

$zs-colors-light-css-vars: ();
@each $color, $value in $zs-colors-light {
  $zs-colors-light-css-vars: map-merge(
    $zs-colors-light-css-vars,
    (#{$color}: var(--color-#{$color}))
  )
}

$font-family-heading: Merriweather, -apple-system, serif;
$font-family-base: Open Sans, -apple-system, "Helvetica Neue", Arial, sans-serif; 
$font-family-monospace: Fira Mono, SFMono-Regular, Menlo, Monaco, Consolas, "Courier New", monospace;
$font-family-logo: Open Sans, -apple-system, "Helvetica Neue", Arial, sans-serif;

:root{  
  @each $color, $value in $zs-colors-light {
    --color-#{$color}: #{$value};
  }
}

html[data-mode='dark'] {
  @each $color, $value in $zs-colors-dark {
      --color-#{$color}: #{$value};
  }
}

@import 'style';
